<template>
  <div
    class="card is-shadowless"
    style="border-radius: 30px"
  >
    <div class="card-content">
      <div class="columns is-multiline">
        <div class="column is-full">
          <div class="title is-4 has-text-blue">Datos de contratación</div>
        </div>

        <div class="column is-full">
          <div class="label">Fecha de inicio de convenio</div>
          <div class="overflow-ellipsis">
            {{ hiring?.entry | formatToDate }}
          </div>
        </div>

        <div class="column is-full">
          <div class="label">Fecha de término de convenio</div>
          <div class="overflow-ellipsis">
            {{ hiring?.finish | formatToDate }}
          </div>
        </div>

        <div class="column is-full">
          <div class="label">Persona con la que se presentará</div>
          <div class="overflow-ellipsis">
            {{ hiring?.name }}
          </div>
        </div>

        <div class="column is-full">
          <div class="label">Dirección a la que se presentará</div>
          <div class="overflow-ellipsis">
            {{ hiring?.address }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hiring: {
      default: () => ({}),
      required: false
    }
  },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>